import React from 'react';

export const Header = () => (
	<header className="outside-text">
		<a href={window.env.LINK_HOME ? window.env.LINK_HOME : '/'}><img src="logo200x98-dark.png" width="200" height="98" /></a>

		<h1>{window.env.STATIC_HEADER_TEXT_BIG}</h1>
		{window.env.STATIC_HEADER_TEXT_SMALL}
	</header>
)
