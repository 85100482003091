import React from 'react';
import {} from './Panel.css';

export const Panel = (props) => (
    <div className="panel">
        <div className="panel-header">{props.header}</div>
        <div className="panel-main">
            {props.children}
        </div>
    </div>
)
