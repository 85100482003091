import React from "react";
import "@fortawesome/fontawesome-free/css/all.css"

const Spinner = (props) => {
    return (
        <span
            style={{
                ...props.style,
            }}
        >
      <i className="fas fa-spinner fa-spin" />
    </span>
    );
};

export default Spinner;
