import React from 'react';
import {Component} from "react"
import {Panel} from './Panel'
import Spinner from "./Spinner";
import {Incident} from "./Incident";
import moment from "moment";
import {} from './OpenIncidents.css';

class OpenIncidents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        fetch(window.env.TICKETS_API)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    tickets: json,
                })
            })
    }

    render() {
        const {isLoaded, tickets} = this.state;

        if (!isLoaded) {
            return <div className="comp-loader"><Spinner/> Loading data...</div>
        } else if (tickets.length > 0) {
            return (
                <>
                    <div className="outside-text">
                        <h1 className="">Open Incidents</h1>
                    </div>
                    {tickets.map(ticket => (
                        <Panel header={moment(ticket.createdAt).format("MMM DD, YYYY")}>
                            <Incident key={ticket.id} subject={ticket.subject} description={ticket.description}
                                      createdAt={ticket.createdAt}
                                      updatedAt={ticket.updatedAt}/>
                            <br/>
                        </Panel>
                    ))}
                </>
            )
        } else {
            return <></>;
        }
    }
}

export default OpenIncidents