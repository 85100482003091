import React from 'react';
import moment from "moment";
import css from './Incident.css'

export const Incident = (props) => (
    <article className="incident">
        <div className="incident-subject">{props.subject}</div>
        <p>
            {props.description}
        </p>
            <div className="incident-date">
                    <span>Created at: </span>{moment(props.createdAt).format("YYYY-MM-DD HH:mm")}
                    <span>Updated at: </span>{moment(props.updatedAt).format("YYYY-MM-DD HH:mm")}
            </div>
    </article>
)
