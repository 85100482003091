import React from "react";
import {Component} from "react"
import "./App.css";
import {Layout} from './components/Layout'
import Status from './components/Status'
import OpenIncidents from "./components/OpenIncidents";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
            title: '',
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <Layout>

                <Status/>
                <OpenIncidents/>

            </Layout>
        )
    }
}

export default App;
