import React from 'react';
import {Header} from './Header';
import {Content} from './Content';
import {Footer} from './Footer';

export const Layout = (props) => (

	<div class="status-page">
		<Header />

		<Content>
			{props.children}
		</Content>

		<Footer />
	</div>
)