import React from 'react';
import {Component} from "react"
import Spinner from "./Spinner";
import {Panel} from "./Panel";
import moment from "moment";

class Status extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
        };
    }

    componentDidMount() {
        fetch(window.env.STATUS_API)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    items: json,
                })
            })
    }

    render() {
        const {isLoaded, items} = this.state;

        if (!isLoaded) {
            return <div className="comp-loader"><Spinner/> Loading data...</div>
        } else {
            return (
                <>
                    {items.map(item => (
                        <>
                            <Panel key={item.componentId} header={item.friendlyName}>
                                <div className="comp-main">
                                    <div className="comp-label">STATUS</div>
                                    <div className={item.status === 'UP' ? 'comp-up' : 'comp-down'}>
                                        {item.status === 'UP' ? 'Operational' : 'Failed'}
                                    </div>
                                    <div className="comp-spacer"></div>
                                    <div className="comp-label">LAST CHECK DATE</div>
                                    <div
                                        className="comp-last-val">{moment(item.lastcheck).format("YYYY-MM-DD HH:mm")}</div>
                                </div>
                            </Panel>
                            <br/>
                        </>
                    ))}
                </>
            )
        }
    }
}

export default Status